/* Add this to your CSS file or use a CSS-in-JS solution */

.success-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.success-screen-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.3s, transform 0.3s; /* Added transition properties */
}

.success-icon {
  color: #4caf50; /* Green color */
  font-size: 3em;
  margin-bottom: 10px;
}
